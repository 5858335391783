import React, { Component } from 'react';

class Footer extends Component {
  state = {};
  render() {
    return (
      <div className='footer'>
        <p align='center'>Built with React. </p>
      </div>
    );
  }
}

export default Footer;
