import React, { Component } from 'react';
import Section from './../section';

class About extends Component {
  state = {};
  render() {
    return (
      <Section title='About Me'>
        <p>
          Hello, I'm Dylan, a computer science student at <a
            href='https://uconn.edu/'
            className='link-blue'
            target='_blank'
            rel='noopener noreferrer'>
            UConn
          </a>.{' '} My passion for learning how to code stems from my involvement in reselling sneakers. From this,
          I've had a passion for technology! {' '}
        </p>
        <p>

        </p>
      </Section>
    );
  }
}

export default About;
