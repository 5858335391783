import React, { Component } from 'react';
import Section from './../section';
import Card from './../card';

class Projects extends Component {
  state = {
    projects: [
      {
        title: 'Crypto DeFi Bot',
        detail:
          'Utilized various APIs to efficiently retrieve, parse, and visualize real-time data on Total Value Locked (TVL) across specific blockchain networks, live cryptocurrency prices, and revenue generated by protocols.',
        tags: ['JavaScript', 'DiscordJS', 'Defillamma' ],
        github: 'https://github.com/dylan-s-young/DeFi-Bot',
        color: 'blue',
      },
      {
        title: 'Twitter Monitor',
        detail: 
          "This project uses an algorithm to monitor Twitter users using Twitter's API. When a user tweets, the data is queried and sent to a Discord webhook.",
        tags: ['Python'],
        github: 'https://github.com/dylan-s-young/Discord-Twitter-Monitor-',
        color: 'yellow',
      }
    ],
  };
  render() {
    return (
      <Section title='projects'>
        <div className='row'>
          {this.state.projects.map((project, index) => (
            <Card project={project} key={index} />
          ))}
        </div>
        <p id='see-more'>
          <a href='https://github.com/dylan-s-young' target='_blank' className='link-white' rel="noreferrer">
            Explore more on GitHub
          </a>
        </p>
      </Section>
    );
  }
}

export default Projects;
